import React, { useEffect, useState } from 'react';

import DataTable from '../../../Components/DataGrid';
import { GET_CASH_RECEIPTS_INQUIRY } from '../../../Graphql/queries/GET_CASHRECEIPTS_INQUIRY';
import { Grid } from '@mui/material';
import SummaryPanel from '../../../Components/SummaryPanel';
import TablePanel from '../../../Components/TablePanel';
import dayjs from 'dayjs';
import jsUtils from '../../../utils/jsUtils';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import { useLazyQuery } from '@apollo/client';

const INITIAL_SUMMARY = [
  {
    title: 'Count :',
    value: 0,
  },
  {
    title: 'Fees :',
    value: '$ 00.00',
  },
  {
    title: 'Expenses :',
    value: '$ 00.00',
  },
  {
    title: 'Surcharges :',
    value: '$ 00.00',
  },
  {
    title: 'Interest :',
    value: '$ 00.00',
  },
  {
    title: 'Taxes :',
    value: '$ 00.00',
  },
  {
    title: 'Advances :',
    value: '$ 00.00',
  },
  {
    title: 'Ledgers :',
    value: '$ 00.00',
  },
  {
    title: 'Applied :',
    value: '$ 00.00',
  },
  {
    title: 'Unapplied :',
    value: '$ 00.00',
  },
  {
    title: 'Total :',
    value: '$ 00.00',
  },
];

const CashReceiptsTab = () => {
  const [rows, setRows] = useState([]);
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);
  const [summary, setSummary] = useState(INITIAL_SUMMARY);

  const [fetchCashReceiptsInquiry] = useLazyQuery(
    GET_CASH_RECEIPTS_INQUIRY,
    {
      variables: state,
      onCompleted: (data) => {
        if (data.getCashReceipts) {
          setRows(data.getCashReceipts.receipts);

          setSummary([
            {
              title: 'Count :',
              value: data.getCashReceipts.count,
            },
            {
              title: 'Fees :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.feesTotal
              ),
            },
            {
              title: 'Expenses :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.expensesTotal
              ),
            },
            {
              title: 'Surcharges :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.surchargesTotal
              ),
            },
            {
              title: 'Interest :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.interestTotal
              ),
            },
            {
              title: 'Taxes :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.taxesTotal
              ),
            },
            {
              title: 'Advances :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.advancesTotal
              ),
            },
            {
              title: 'Ledgers :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.ledgersTotal
              ),
            },
            {
              title: 'Applied :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.appliedTotal
              ),
            },
            {
              title: 'Unapplied :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.unappliedTotal
              ),
            },
            {
              title: 'Total :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getCashReceipts.totalSum
              ),
            },
          ]);
        } else {
          setRows([]);
          setSummary(INITIAL_SUMMARY);
        }
      },
    }
  );

  useEffect(() => {
    if (inquiryTarget) {
      let { client, matter, startDate, endDate } = inquiryTarget;

      if (client) {
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (state) {
      fetchCashReceiptsInquiry();
    }
  }, [state]);

  return (
    <Grid container spacing={2} data-testid="cash-receipts-container">
      <Grid item xs={3} sm={3} md={3} lg={3} xl={2.3}>
        <SummaryPanel data-testid="summary-panel" List={summary} />
      </Grid>
      <Grid item xs={9} sm={9} md={9} lg={9} xl={9.7}>
        <TablePanel title="Cash Receipts" data-testid="table-panel">
          <DataTable rows={rows} columns={columns} />
        </TablePanel>
      </Grid>
    </Grid>
  );
};

export default CashReceiptsTab;

const columns = [
  {
    field: 'Id',
    headerName: 'ID',
    width: 70,
    valueGetter: (params) => params.row.id,
    isCalcutateIcon: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 120,
    valueGetter: (params) => params.row.entryPaymentType,
    isCalcutateIcon: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 90,
    valueGetter: (params) => params.row.status,
    isCalcutateIcon: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 120,
    valueGetter: (params) =>
      dayjs(params.row.entryDate).format('MM-DD-YYYY'),
    isCalcutateIcon: false,
  },
  {
    field: 'client',
    headerName: 'Client',
    width: 130,
    valueGetter: (params) => params.row.clientCode,
    isCalcutateIcon: false,
  },
  {
    field: 'matter',
    headerName: 'Matter',
    width: 130,
    valueGetter: (params) => params.row.jobCode,
    isCalcutateIcon: false,
  },
  {
    field: 'fees',
    headerName: 'Fees',
    width: 130,
    valueGetter: (params) =>
      jsUtils.negativeNumberFormatter(params.row.feeAmount),
    isCalculateIcon: false,
  },
  {
    field: 'expenses',
    headerName: 'Expenses',
    width: 130,
    valueGetter: (params) =>
      jsUtils.negativeNumberFormatter(params.row.expenseAmount),
    isCalcutateIcon: true,
  },
  {
    field: 'interest',
    headerName: 'Interest',
    width: 130,
    valueGetter: (params) =>
      jsUtils.negativeNumberFormatter(params.row.interestAmount),
    isCalcutateIcon: true,
  },
  {
    field: 'surcharges',
    headerName: 'Surcharges',
    width: 120,
    valueGetter: (params) =>
      jsUtils.negativeNumberFormatter(params.row.surchargeAmount),
    isCalcutateIcon: true,
  },
  {
    field: 'taxes',
    headerName: 'Taxes',
    width: 130,
    valueGetter: (params) =>
      jsUtils.negativeNumberFormatter(params.row.taxAmount),
    isCalcutateIcon: true,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 130,
    valueGetter: (params) =>
      jsUtils.negativeNumberFormatter(params.row.amount),
    isCalcutateIcon: true,
  },
  {
    field: 'entryNumber',
    headerName: 'Entry Number',
    width: 180,
    valueGetter: (params) =>
      params.row.entryNumber,
    isCalcutateIcon: true,
  },
  {
    field: 'entryPayor',
    headerName: 'Entry Payor',
    width: 160,
    valueGetter: (params) => params.row.entryPayor,
    isCalcutateIcon: true,
  },
  {
    field: 'entryRemarks',
    headerName: 'Entry Remarks',
    width: 180,
    valueGetter: (params) => params.row.entryDescription,
    isCalcutateIcon: true,
  },
];
