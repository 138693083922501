import React, { useEffect, useState } from 'react';

import DataTable from '../../../Components/DataGrid';
import { GET_INQUIRY_AR_ADJUSTMENTS } from '../../../Graphql/queries/GET_INQUIRY_AR_ADJUSTMENTS';
import { Grid } from '@mui/material';
import SummaryPanel from '../../../Components/SummaryPanel';
import TablePanel from '../../../Components/TablePanel';
import dayjs from 'dayjs';
import jsUtils from '../../../utils/jsUtils';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import { useLazyQuery } from '@apollo/client';

export const summaryFields = [
  {
    title: 'Count:',
    defaultValue: 0,
    value: (data) => data.getInquiryArAdjustments.count,
  },
  {
    title: 'Fees:',
    defaultValue: '$ 00.00',
    value: (data) => jsUtils.negativeCurrencyFormatter(data.getInquiryArAdjustments.feesTotal)
  },
  {
    title: 'Expenses:',
    defaultValue: '$ 00.00',
    value: (data) => jsUtils.negativeCurrencyFormatter(data.getInquiryArAdjustments.expensesTotal)
  },
  {
    title: 'Surcharges:',
    defaultValue: '$ 00.00',
    value: (data) => jsUtils.negativeCurrencyFormatter(data.getInquiryArAdjustments.surchargesTotal)
  },
  {
    title: 'Interest:',
    defaultValue: '$ 00.00',
    value: (data) => jsUtils.negativeCurrencyFormatter(data.getInquiryArAdjustments.interestTotal)
  },
  {
    title: 'Taxes:',
    defaultValue: '$ 00.00',
    value: (data) => jsUtils.negativeCurrencyFormatter(data.getInquiryArAdjustments.taxesTotal)
  },
  {
    title: 'Advances:',
    defaultValue: 'N/A',
    value: (data) => data.getInquiryArAdjustments.advancesTotal || 'N/A'
  },
  {
    title: 'Ledgers:',
    defaultValue: 'N/A',
    value: (data) => data.getInquiryArAdjustments.ledgersTotal || 'N/A'
  },
  {
    title: 'Applied:',
    defaultValue: 'N/A',
    value: (data) => data.getInquiryArAdjustments.appliedTotal || 'N/A'
  },
  {
    title: 'Unapplied:',
    defaultValue: 'N/A',
    value: (data) => data.getInquiryArAdjustments.unappliedTotal || 'N/A'
  },
  {
    title: 'Total:',
    defaultValue: '$ 00.00',
    value: (data) => jsUtils.negativeCurrencyFormatter(data.getInquiryArAdjustments.totalSum)
  },
];

export const initialSummary = summaryFields.map((field) => ({
  title: field.title,
  value: field.defaultValue
}));

export const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    isCalcutateIcon: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    isCalcutateIcon: false,
  },
  {
    field: 'entryDate',
    headerName: 'Date',
    width: 120,
    valueGetter: (params) => dayjs(params.row.entryDate).format('MM-DD-YYYY'),
    isCalcutateIcon: false,
  },
  {
    field: 'clientCode',
    headerName: 'Client',
    width: 130,
    isCalcutateIcon: false,
  },
  {
    field: 'jobCode',
    headerName: 'Matter',
    width: 130,
    isCalcutateIcon: false,
  },
  {
    field: 'adjustmentCode',
    headerName: 'Reason Code',
    width: 130,
    isCalcutateIcon: false,
  },
  {
    field: 'feeAmount',
    width: 140,
    headerName: 'Fees',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.feeAmount),
  },
  {
    field: 'expenseAmount',
    width: 140,
    headerName: 'Expenses',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.expenseAmount),
  },
  {
    field: 'interestAmount',
    width: 140,
    headerName: 'Interest',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.interestAmount),
  },
  {
    field: 'taxAmount',
    width: 140,
    headerName: 'Taxes',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.taxAmount),
  },
  {
    field: 'retainerAmount',
    width: 140,
    headerName: 'Retainer',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.retainerAmount),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 100,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.amount),
    isCalcutateIcon: true,
  },
  {
    field: 'entryDescription',
    width: 300,
    headerName: 'Remarks',
    isCalcutateIcon: false,
  },
];

const ARAdjustmentsTab = () => {
  const [rows, setRows] = useState([]);
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);
  const [Summary, setSummary] = useState(initialSummary);

// eslint-disable-next-line no-unused-vars
// Get Ledger AR Inquiry
  const [fetchARLedgerInquiry] =
    useLazyQuery(GET_INQUIRY_AR_ADJUSTMENTS, {
      variables: { inquiryArAdjustmentRequest: state },
      onCompleted: (data) => {
        if (data.getInquiryArAdjustments.inquiryArAdjustment) {
          setRows(data.getInquiryArAdjustments.inquiryArAdjustment);

          setSummary(summaryFields.map((field) => ({
            title: field.title,
            value: field.value(data)
          })));
        } else {
          setRows([]);
          setSummary(initialSummary);
        }
      },
    });

  useEffect(() => {
    if (inquiryTarget) {
      // Destructure data
      let { client, matter, startDate, endDate } = inquiryTarget;

      if (client) {
        // Set states
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [inquiryTarget]);

  // Fetch AR ledger inquiry when state changes
  useEffect(() => {
    if (state) {
      fetchARLedgerInquiry();
    }
  }, [state]);

  return (
    <Grid container spacing={2} data-testid='ar-adjustments-container'>
    <Grid item xs={3} sm={3} md={3} lg={3} xl={2.3}>
      <SummaryPanel List={Summary} data-testid='summary-panel'/>
    </Grid>
    <Grid item xs={9} sm={9} md={9} lg={9} xl={9.7}>
      <TablePanel title="A/R Adjustments" data-testid="table-panel">
        <DataTable rows={rows} columns={columns} />
      </TablePanel>
    </Grid>
  </Grid>
  );
};

export default ARAdjustmentsTab;
