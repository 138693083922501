import React from 'react';
import config from '../../config';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client';
import fetch from 'cross-fetch';

const ApolloContextProvider = ({ children, getUri }) => {
  const httpLink = createHttpLink({
    uri: config.baseUrl, // Base URL
    fetch,
  });

  const dynamicUriLink = new ApolloLink((operation, forward) => {
    const queryName = operation.operationName;
    console.log('Query Name:', queryName); // Log the query name

    const uri = getUri(queryName);
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        sid: localStorage.getItem('sid'),
      },
      uri: `${config.baseUrl}${uri}`,
    }));
    return forward(operation);
  });

  const client = new ApolloClient({
    link: ApolloLink.from([dynamicUriLink, httpLink]),
    cache: new InMemoryCache({
      dataIdFromObject: (o) => {
        o.id
          ? `${o.__typename}-${o.id}`
          : `${o.__typename}-${o.cursor}`;
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloContextProvider;