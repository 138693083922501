import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { CssMultiTextField, CssTextField, StyledLoadingButton } from "../../../Components/CommonComponent";
import { useEffect, useState } from "react";

import ADD_NOTE from "../../../Graphql/queries/ADD_NOTE";
import GET_NOTES_DATA from "../../../Graphql/queries/GET_NOTES_DATA";
import CloseIcon from '@mui/icons-material/Close';
import StyledButton from "../../../Components/CommonComponent/StyledLoadingButton";
import styled from "@emotion/styled";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import { useMutation } from "@apollo/client";

const CustomDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: '1px 20px 20px 20px',
  },
}));

const NotesModal = (props) => {
  const { inquiryTarget } = useInquiryTarget();
  const [noteData, setNoteData] = useState({
    noteId:"",
    entityId:"",
    subject:"",
    description:"",
    category:"",
  });

  const [addNote] = useMutation(ADD_NOTE, {
    onCompleted: (data) => {
      props.showSnackbar(`Note saved with ID: ${data.saveNote.id}`, "success");
      handleClose();
    },
    onError: (error) => {
      props.showSnackbar(`Error saving note: ${error.message}`, "error");
    },
    refetchQueries: [GET_NOTES_DATA]
  });

  useEffect(()=>{
    const {type, data} = props;
    if(type==="add"){
      setNoteData({
        noteId:"New",
        entityId:noteData.entityId,
        subject:"",
        description:"",
        category:"",
      });
    } else if(type==="edit"){
        setNoteData({
          noteId: data.id,
          entityId: data.entityId,
          subject: data.subject,
          description: data.description,
          category: data.category,
        });
    }
  },[props.type, props.data]);

  useEffect(() => {
    if (inquiryTarget) {
      let { client,matter } = inquiryTarget;
      if (client || matter) {
        setNoteData(prev=>({
          ...prev,
          entityId:matter?.matterId ?  parseInt(matter.matterId) : parseInt(client.clientId),
        }));
      }
    }
  }, [inquiryTarget]);

  const handleClose = () => {
    props.setOpen(false);
    setNoteData({
      noteId:"New",
      entityId:noteData.entityId,
      subject:"",
      description:"",
      category:"",
    });
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setNoteData({
      ...noteData,
      [name]: value
    });
  }

  const handleDelete = () => {
    props.delete();
    props.setOpen(false);
  }
  const handleSave = () => {
    const id = noteData.noteId==="New" ? null : Number(noteData.noteId);
    const saveNoteRequest = {
      noteId: id,
      entityId: noteData.entityId,
      subject: noteData.subject,
      description: noteData.description,
      category: noteData.category
    };
    noteData && addNote({
      variables: { saveNoteRequest }
    });
  }

  return (
    <CustomDialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Notes: {noteData.subject}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#0050c2',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className="space-y-3 p-2">
          <CssTextField
            label="ID"
            fullWidth
            name="id"
            disabled
            InputLabelProps={{ shrink: true }}
            size="small"
            value={noteData.noteId}
          />
        </Box>
        <Box className="space-y-3 p-2">
          <CssTextField
            label="Subject"
            fullWidth
            name="subject"
            InputLabelProps={{ shrink: true }}
            size="small"
            value={noteData.subject}
            onChange={handleChange}
          />
        </Box>
        <Box className="space-y-3 p-2">
          <CssMultiTextField
            fullWidth
            label="Narrative"
            name="description"
            value={noteData.description}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleChange}
          />
        </Box>
        <Box className="space-y-3 p-2">
          <CssTextField
            label="Category"
            fullWidth
            name="category"
            InputLabelProps={{ shrink: true }}
            size="small"
            value={noteData.category}
            onChange={handleChange}
          />
        </Box>
        <Box className="space-y-3 p-2">
          <div className="view-more-button">
            <Stack>
              {(props?.type ==='edit') && <StyledButton
                style={{
                  color: '#0050C3',
                  paddingLeft: '0px',
                }}
                onClick={handleDelete}
                disabled={props.type==="add"}
              >
                Delete
              </StyledButton>}
            </Stack>
            <Stack>
              <StyledLoadingButton
                onClick={handleClose}
                id="cancel"
                variant="outlined"
                style={{
                  color: '#0050C3',
                  minWidth: '48px',
                  maxWidth: '48px',
                }}
              >
                Cancel
              </StyledLoadingButton>
              <StyledLoadingButton
                onClick={handleSave}
                id="save"
                variant="contained"
                style={{
                  backgroundColor: '#0050C3',
                  minWidth: '48px',
                  maxWidth: '48px',
                }}
              >
                Save
              </StyledLoadingButton>
            </Stack>
          </div>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
}

export default NotesModal;