import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import { useLazyQuery } from '@apollo/client';
import { GET_EXPENSE_INQUIRY } from '../../../Graphql/queries/GET_EXPENSE_INQUIRY';
import dayjs from 'dayjs';
import jsUtils from '../../../utils/jsUtils';
import SummaryPanel from '../../../Components/SummaryPanel';
import TablePanel from '../../../Components/TablePanel';
import DataTable from '../../../Components/DataGrid';

const INITIAL_SUMMARY = [
  {
    title: 'Count :',
    value: 0,
  },
  {
    title: 'Billable Amount :',
    value: '$ 00.00',
  },
];
const ExpensesTab = () => {
  const [rows, setRows] = useState([]);
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);
  const [summary, setSummary] = useState(INITIAL_SUMMARY);
  // eslint-disable-next-line no-unused-vars
  const [fetchExpensesInquiry] =
    useLazyQuery(GET_EXPENSE_INQUIRY, {
      variables: {
        clientId: state?.clientId,
        jobId: state?.jobId,
        startDate: state?.startDate,
        endDate: state?.endDate,
      },
      onCompleted: (data) => {
        if (data.getExpenseInquiry) {
          setRows(data.getExpenseInquiry);

          setSummary([
            {
              title: 'Count :',
              value: data.getExpenseInquiry.length,
            },
            {
              title: 'Billable Amount :',
              value: jsUtils.currencyFormatter(
                data.getExpenseInquiry.reduce(
                  (acc, curr) => acc + curr.Amount,
                  0
                )
              ),
            },
          ]);
        } else {
          setRows([]);
          setSummary(INITIAL_SUMMARY);
        }
      },
    });

  useEffect(() => {
    if (inquiryTarget) {
      let { client, matter, startDate, endDate } = inquiryTarget;

      if (client) {
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (state) {
      fetchExpensesInquiry();
    }
  }, [state]);

  return (
    <Grid container spacing={2}>
      <Grid item sx={3} sm={3} md={3} lg={3} xl={2.3}>
        <SummaryPanel List={summary} />
      </Grid>
      <Grid item sx={9} sm={9} md={9} lg={9} xl={9.7}>
        <TablePanel title="Expenses">
          <DataTable rows={rows} columns={columns} />
        </TablePanel>
      </Grid>
    </Grid>
  );
};

export default ExpensesTab;

const columns = [
  {
    field: 'Id',
    headerName: 'ID',
    width: 70,
    valueGetter: (params) => params.row.Id,
    isCalcutateIcon: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 90,
    valueGetter: (params) => params.row.Status,
    isCalcutateIcon: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 120,
    valueGetter: (params) =>
      dayjs(params.row.ActivityDate).format('MM-DD-YYYY'),
    isCalcutateIcon: false,
  },
  {
    field: 'billable',
    headerName: 'Billable',
    width: 130,
    valueGetter: (params) => params.row.Billable,
    isCalcutateIcon: false,
  },
  {
    field: 'timeKeeper',
    headerName: 'Time Keeper',
    width: 130,
    valueGetter: (params) => params.row.EmployeeCode,
    isCalcutateIcon: false,
  },
  {
    field: 'client',
    headerName: 'Client',
    width: 130,
    valueGetter: (params) => params.row.ClientCode,
    isCalcutateIcon: false,
  },
  {
    field: 'matter',
    headerName: 'Matter',
    width: 130,
    valueGetter: (params) => params.row.JobCode,
    isCalcutateIcon: false,
  },
  {
    field: 'expensesCode',
    headerName: 'Expenses Code',
    width: 130,
    valueGetter: (params) => params.row.ExpenseCode,
    isCalculateIcon: false,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 130,
    valueGetter: (params) => params.row.Quantity,
    isCalcutateIcon: true,
  },
  {
    field: 'rate',
    headerName: 'Rate',
    width: 130,
    valueGetter: (params) =>
      jsUtils.currencyFormatter(params.row.Rate),
    isCalcutateIcon: true,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 130,
    valueGetter: (params) =>
      jsUtils.currencyFormatter(params.row.Amount),
    isCalcutateIcon: true,
  },
  {
    field: 'bill',
    headerName: 'Bill',
    width: 120,
    valueGetter: (params) => params.row.BillID,
    isCalcutateIcon: true,
  },
  {
    field: 'billedQuantity',
    headerName: 'Billed Quantity',
    width: 140,
    valueGetter: (params) => params.row.BilledQuantity,
    isCalcutateIcon: true,
  },
  {
    field: 'billedRate',
    headerName: 'Billed Rate',
    width: 130,
    valueGetter: (params) =>
      jsUtils.currencyFormatter(params.row.BilledRate),
    isCalcutateIcon: true,
  },
  {
    field: 'billedAmount',
    headerName: 'Billed Amount',
    width: 140,
    valueGetter: (params) =>
      jsUtils.currencyFormatter(params.row.BilledAmount),
    isCalcutateIcon: true,
  },
  {
    field: 'billedDiscount',
    headerName: 'Billed Discount',
    width: 150,
    valueGetter: (params) =>
      jsUtils.currencyFormatter(params.row.BilledDiscount),
    isCalcutateIcon: true,
  },
  {
    field: 'billedTotal',
    headerName: 'Billed Total',
    width: 130,
    valueGetter: (params) =>
      jsUtils.currencyFormatter(params.row.BilledNetDiscount),
    isCalcutateIcon: true,
  },
  {
    field: 'narratives',
    headerName: 'Narratives',
    width: 130,
    valueGetter: (params) => params.row.Description,
    isCalcutateIcon: true,
  },
];
