import { gql } from '@apollo/client';

export const GET_CASH_RECEIPTS_INQUIRY = gql`
  query GetCashReceiptsInquiry(
    $clientId: Int!
    $jobId: Int!
    $startDate: LocalDate!
    $endDate: LocalDate!
  ) {
    getCashReceipts(
      cashReceiptsRequest: {
        clientId: $clientId
        jobId: $jobId
        startDate: $startDate
        endDate: $endDate
        showWip: true
      }
    ) {
      count
      feesTotal
      expensesTotal
      surchargesTotal
      interestTotal
      taxesTotal
      advancesTotal
      ledgersTotal
      appliedTotal
      unappliedTotal
      totalSum
      receipts {
        id
        activityTypeID
        entryDate
        statusID
        status
        customerID
        clientCode
        jobCode
        clientDescription
        jobDescription
        feeAmount
        expenseAmount
        taxAmount
        interestAmount
        surchargeAmount
        retainerAmount
        amount
        entryPayor
        entryNumber
        entryDescription
        entryPaymentType
      }
    }
  }
`;
