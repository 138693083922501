import { gql } from '@apollo/client';

export const GET_RECORDS = gql`
query GetRecords($clientId: Int!, $jobId: Int) {
    getRecords(clientId: $clientId, jobId: $jobId,) {
      count  
      records{
        id
        typeCode
        code
        reference
        locationDescription
        clientCode
        jobCode
        description
        status

        
      }
    }
  }
`;