import { gql } from "@apollo/client";

const GET_NOTES_DATA = gql`
  query getNotes(
    $clientId: Int!
    $jobId: Int
  ) {
  getNotes(request: { 
    clientId: $clientId, 
    jobId: $jobId, 
    }) {
        count  
        notes{
            id
            createdById
            createdDate
            modifiedById
            modifiedDate
            modifiedBy
            entityId            
            entityTypeId
            entityTypeDescription
            subject
            description
            category           
        }       
    }
}

`;

export default GET_NOTES_DATA;
