import { gql } from "@apollo/client";

export const GET_CONFLICTS_DATA = gql`
    query getInquiryConflicts(
        $clientId: Int!
        $jobId: Int!
        ) {
        getConflicts(
            conflictRequest: { clientId: $clientId, jobId: $jobId}
        ){    
            conflicts{
                id
                conflictName
                remarks
                requestor
                searchTerms
                searched
                searchedBy
                searchedDate
                hits
                approverStatus
                approverDecision
                finalDecision
            }
        }
    }
`;