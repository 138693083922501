import "./styles.scss";

import { Box, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { CalendarToday, ContentPaste } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import DataTable from "../../../Components/DataGrid";
import GET_CALENDAR_DATA from "../../../Graphql/queries/GET_CALENDAR_DATA";
import dayjs from 'dayjs';
import jsUtils from "../../../utils/jsUtils";
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import { useLazyQuery } from "@apollo/client";

const meetingColumns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 80,
    flex: 1
  },
  {
    field: "eventTypeCode",
    headerName: "Type",
    minWidth: 150,
    flex: 1
  },
  {
    field: "eventTypeDescription",
    headerName: "Type Description",
    width: 180,
    flex: 2
  },
  {
    field: "isPrivate",
    headerName: "Private",
    valueGetter:(params)=>params.row.isPrivate? 'Yes':'No',
    minWidth: 150,
    flex: 1
  },
  {
    field: "createdDate",
    headerName: "Created Date",
    valueGetter: (params) =>
      params.row.createdDate ? dayjs(params.row.createdDate).format('MM-DD-YYYY') : '',
    minWidth: 150,
    flex:1
  },
  {
    field: "startDate",
    headerName: "Start Date",
    valueGetter: (params) =>
      params.row.startDate ? dayjs(params.row.startDate).format('MM-DD-YYYY') : '', // add null check 
    minWidth: 150,
    flex: 1
  },
  {
    field: "startTime",
    headerName: "Start Time",
    valueGetter: (params)=>jsUtils.convertTo12Hour(params.row.startTime),
    minWidth: 150,
    flex: 1
  },
  {
    field: "recurrenceEndDate",
    headerName: "End Date",
    valueGetter: (params) =>
      params.row.recurrenceEndDate ? dayjs(params.row.recurrenceEndDate).format('MM-DD-YYYY') : '', // add null check
    minWidth: 150,
    flex: 1
  },
  {
    field: "endTime",
    headerName: "End Time",
    valueGetter: (params)=>jsUtils.convertTo12Hour(params.row.endTime),
    minWidth: 150,
    flex: 1
  },
  {
    field: "name",
    headerName: "Subject",
    minWidth: 150,
    flex: 1
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 150,
    flex: 1
  },
  {
    field: "clientCode",
    headerName: "Client",
    minWidth: 150,
    flex: 1
  },
  {
    field: "clientType",
    headerName: "Client Type",
    minWidth: 150,
    flex: 1
  },
  {
    field: "matterCode",
    headerName: "Matter",
    minWidth: 150,
    flex: 1
  },
  {
    field: "matterType",
    headerName: "Matter Type",
    minWidth: 150,
    flex: 1
  },
  {
    field: "billAtty",
    headerName: "Bill Atty",
    minWidth: 150,
    flex: 1
  },
  {
    field: "billAttyName",
    headerName: "Bill Atty Name",
    minWidth: 150,
    flex: 1
  },
  {
    field: "workFlowCode",
    headerName: "Workflow", 
    minWidth: 180,
    flex: 1
  },
  

];
const tasksColumns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 80,
    flex: 1
  },
  {
    field: "eventTypeCode",
    headerName: "Event Type",
    width: 150,
    flex: 1
  },
  {
    field: "eventTypeDescription",
    headerName: "Type Description",
    minWidth: 150,
    flex: 1
  },
  {
    field: "isPrivate",
    headerName: "Private",
    valueGetter:(params)=>params.row.isPrivate? 'Yes':'No',
    minWidth: 150,
    flex: 1
  },
  {
    field: "deadlineDate",
    headerName: "Deadline",
    valueGetter: (params) =>
      params.row.deadlineDate ? dayjs(params.row.deadlineDate).format('MM-DD-YYYY'):'',
    minWidth: 150,
    flex: 1
  },
  {
    field: "completedDate",
    headerName: "Completed Date",
    valueGetter: (params) =>
      params.row.completedDate ? dayjs(params.row.completedDate).format('MM-DD-YYYY') : "",
    minWidth: 150,
    flex: 1
  },
  {
    field: "name",
    headerName: "Subject",
    minWidth: 150,
    flex: 1
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 150,
    flex: 1
  },
  {
    field: "clientCode",
    headerName: "Client",
    minWidth: 150,
    flex: 1
  },
  {
    field: "matterCode",
    headerName: "Matter",
    minWidth: 150,
    flex: 1
  },
  {
    field: "billAtty",
    headerName: "Bill Atty",
    minWidth: 150,
    flex: 1
  },
  {
    field: "billAttyName",
    headerName: "Bill Atty Name",
    minWidth: 150,
    flex: 1
  },
  {
    field: "createdDate",
    headerName: "Created Date",
    valueGetter: (params) =>
      params.row.createdDate ? dayjs(params.row.createdDate).format('MM-DD-YYYY'):'',
    minWidth: 150,
    flex: 1,
  },
];
const tabOptions = [
  {
    label: "Meetings",
    icon: <CalendarToday sx={{ fontSize: "1.3rem" }} />,
  },
  {
    label: "Tasks",
    icon: <ContentPaste sx={{ fontSize: "1.3rem" }} />,
  },
];
const subTabs={
  meeting:0,
  tasks:1
}
export default function CalendarTab() {
  const [meetingsList, setMeetingList] = useState([]);
  const [tasksList, setTasksList] = useState([]);
  const [selectedSubTab, setSelectedSubTab] = useState(subTabs.meeting);
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);

  const [fetchCalendarData, { loading }] = useLazyQuery(
    GET_CALENDAR_DATA,
    {
      variables: state,
      onCompleted: (data) => {
        const meetings = data?.getCalendar?.meetings || [];
        const tasks = data?.getCalendar?.tasks || [];
        setMeetingList(meetings);
        setTasksList(tasks);
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
useEffect(() => {
    if (inquiryTarget) {
      // Destructure data
      const { client, matter } = inquiryTarget;

      if (client) {
        // Set states
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : null,
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if(state){
      fetchCalendarData();
    }
  }, [state]);

  useEffect(() => {
    // fetchRecordsByOfficeId();
  }, []);

  const handleChange = (event,value) => {
    console.log(value);
    setSelectedSubTab(value)
  };

  return (
    <Box className="tab-content">
      <Box className="content-header">
        <Typography variant="h6" paddingLeft={1}>
          Calendar
        </Typography>
        <Box display="flex" alignItems="center">
          <Tabs
            value={selectedSubTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            scrollButtons={false}
            aria-label="simple tabs example"
            sx={{
              "& .MuiTabs-scroller": {
                marginBottom: "0px",
              },
              marginBottom: "-14px",
              minHeight: "44px",
              mt: -2,
              "& button": {
                display: "flex",
                flexDirection: "row",
                minHeight: "40px",
                color: "#00000099",
                textTransform: "capitalize",
                gap: "8px",
              },
              "& button.Mui-selected": {
                color: "#1976d2",
                borderRadius: "8px 8px 0 0",
                "& svg": {
                  color: "#1976d2",
                },
              },
              "& .MuiTabs-scrollButtons.Mui-disabled": {
                opacity: 1,
              },
              "& .MuiTabs-scrollButtons > svg": {
                color: "#00000099 !important",
              },
              "& button > svg": {
                marginBottom: "0px !important",
                color: "#00000099",
              },
            }}
          >
            {tabOptions.map((tab, index) => (
              <Tab key={index} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box>
        <DataTable rows={selectedSubTab === subTabs.meeting?meetingsList:tasksList} columns={selectedSubTab ===subTabs.meeting?meetingColumns:tasksColumns} />
      </Box>
    </Box>
  );
}
