import { gql } from "@apollo/client";

const GET_CALENDAR_DATA = gql`
  query getCalendar(
    $clientId: Int!
    $jobId: Int
  ) {
  getCalendar(clientJobRequest: {
  clientId: $clientId, 
  jobId: $jobId
  }) {
        meetingsCount  
        tasksCount
        meetings{
           id
            eventTypeCode
            eventTypeDescription
            isPrivate
            startDate
            startTime
            recurrenceEndDate
            endTime            
            name
            status
            clientCode
            clientType
            matterCode
            matterType
            billAtty
            billAttyName 
            workFlowCode
            workFlowDescription
            createdDate  
        }
        tasks{
           id
            eventTypeCode
            eventTypeDescription
            isPrivate
            deadlineDate
            completedDate
            name
            status
            clientCode
            matterCode
            billAtty
            billAttyName 
            createdDate               
        }         
    }
        
}

`;

export default GET_CALENDAR_DATA;
