import { Card, CardContent, Typography } from '@mui/material';

import ARLedgerTab from '../ARLedgerTab/ARLedgerTab';
import AdvanceLedger from '../AdvanceLedgerTab';
import ApolloContextProvider from '../../../Graphql/ApolloContextProvider';
import BillsTab from '../BillsTab';
import ClientMatterSearchBar from '../ClientMatterSearchBar';
import ConflictsTab from '../ConflictsTab';
import ContactTab from '../ContactTab';
import ExpensesTab from '../ExpensesTab';
import FeesTab from '../FeesTab';
import { PrimaryTabsOption } from '../TabNavigator/tabOptions';
import React from 'react';
import RecordsTab from '../RecordsTab';
import SummaryTab from '../SummaryTab';
import TabNavigator from '../TabNavigator';
import TrustLedgerTab from '../TrustLedgerTab/TrustLedgerTab';
import WIPLedgerTab from '../WIPLedgerTab';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import CashReceiptsTab from '../CashReceipts';
import ARAdjustmentsTab from '../ARAdjustmentsTab/ARAdjustmentsTab';
import CalendarTab from '../CalendarTab';
import NotesTab from '../NotesTab';

const tabPanels = [
  {
    component: <SummaryTab />,
    value: 0,
  },
  {
    component: <ContactTab />,
    value: 1,
  },
  {
    component: <CalendarTab />,
    value: 2,
  },
  {
    component: <FeesTab />,
    value: 3,
  },
  {
    component: <ExpensesTab />,
    value: 4,
  },
  {
    component: <BillsTab />,
    value: 5,
  },
  {
    component: <CashReceiptsTab />,
    value: 6,
  },
  {
    component: <ARAdjustmentsTab />,
    value: 7,
  },
  {
    component: <WIPLedgerTab />,
    value: 8,
  },
  {
    component: <ARLedgerTab />,
    value: 9,
  },
  {
    component: <TrustLedgerTab />,
    value: 10,
  },
  {
    component: <AdvanceLedger />,
    value: 11,
  },
  {
    component: <NotesTab />,
    value: 12,
  },
  {
    component: <RecordsTab />,
    value: 13,
  },
  {
    component: <ConflictsTab />,
    value: 14,
  },
];

const getUri = (queryName) => {
  // Determine the URI based on the query name
  if (queryName === 'deleteNote') {
    return '/main/graphql';
  }
  return '/matter-inquiry/graphql';
};

const Main = () => {
  // eslint-disable-next-line no-unused-vars
  const { inquiryTarget } = useInquiryTarget();
  return (
    <Card style={{ height: '100%' }}>
      <CardContent style={styleContent}>
        <ApolloContextProvider getUri={()=>"/main/graphql"}>
          <ClientMatterSearchBar />
        </ApolloContextProvider>
        <Typography style={styleTypography}>
          {inquiryTarget?.client
            ? `${inquiryTarget?.client?.clientDesc} ${
                inquiryTarget?.matter
                  ? `- ${inquiryTarget.matter.matterDesc}`
                  : ' '
              }`
            : '  '}
        </Typography>
        <ApolloContextProvider getUri={getUri}>
          <TabNavigator
            tabPanels={tabPanels}
            tabsOption={PrimaryTabsOption}
            scrollButtons={true}
          />
        </ApolloContextProvider>
      </CardContent>
    </Card>
  );
};

const styleTypography = {
  fontFamily: 'Roboto',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '20px',
  height: '20px',
  letterSpacing: '0.14px',
  width: 'fit-content',
  marginTop: '20px',
  marginBottom: '20px',
};
const styleContent = {
  height: '790px',
};

export default Main;
