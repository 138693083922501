import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorIcon from '@mui/icons-material/Error';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Warning } from '@mui/icons-material';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PaymentsIcon from "@mui/icons-material/Payment";


export const PrimaryTabsOption = [
  {
    label: 'Summary',
    icon: <ContentPasteIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'Contacts',
    icon: <PersonIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'Calendar',
    icon: <CalendarTodayIcon sx={{ fontSize: '1.3rem' }} />
  },
  {
    label: 'Fees',
    icon: <CreditCardIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'Expenses',
    icon: <ErrorIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'Bills',
    icon: <ReceiptIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'Cash Receipts',
    icon: <CreditCardIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
      label: 'A/R Adjustments',
      icon: <ViewModuleIcon sx={{ fontSize: '1.3rem' }} />
  },
  {
    label: 'WIP Ledger',
    icon: <ViewModuleIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'AR Ledger',
    icon: <ViewModuleIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'Trust Ledger',
    icon: <ViewModuleIcon sx={{ fontSize: '1.3rem' }} />,
  },
  // {
  //     label: 'Trust Ledger',
  //     icon: <ViewModuleIcon sx={{ fontSize: '1.3rem' }} />
  // },
  {
    label: 'Advance Ledger',
    icon: <ViewModuleIcon sx={{ fontSize: '1.3rem' }} />,
  },
  // {
  //     label: 'Bill Collections',
  //     icon: <ReceiptLongIcon sx={{ fontSize: '1.3rem' }} />
  // },
  // {
  //     label: 'Trust Collections',
  //     icon: <PaymentsIcon sx={{ fontSize: '1.3rem' }} />
  // },
  {
      label: 'Notes',
      icon: <PaymentsIcon sx={{ fontSize: '1.3rem' }} />
  },
  // {
  //     label: 'Attachments',
  //     icon: <PaymentsIcon sx={{ fontSize: '1.3rem' }} />
  // },
  // {
  //     label: 'Documents',
  //     icon: <PaymentsIcon sx={{ fontSize: '1.3rem' }} />
  // },
  {
    label: 'Records',
    icon: <DashboardIcon sx={{ fontSize: '1.3rem' }} />,
  },
  {
    label: 'Conflicts',
    icon: <Warning sx={{ fontSize: '1.3rem' }} />,
  },
];

export const SummaryTabsOption = [
  {
    label: 'Summary',
    icon: null,
  },
  {
    label: 'Hours',
    icon: null,
  },
  {
    label: 'Activity',
    icon: null,
  },
  {
    label: 'Aged WIP',
    icon: null,
  },
  {
    label: 'Aged AR',
    icon: null,
  },
  {
    label: 'On Account',
    icon: null,
  },
];
