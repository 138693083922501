import "./styles.scss";

import {
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import ConfirmationDialog from '../../../Components/CommonComponent/ConfirmationDialog/ConfirmationDialog';
import DELETE_NOTE from "../../../Graphql/queries/DELETE_NOTE";
import DataTable from "../../../Components/DataGrid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import GET_NOTES_DATA from "../../../Graphql/queries/GET_NOTES_DATA";
import { GridActionsCellItem } from "@mui/x-data-grid";
import NotesModal from "./NotesModal";
import StyledLoadingButton from "../../../Components/CommonComponent/StyledLoadingButton";
import dayjs from "dayjs";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import useSnackbar from '../../../Components/CommonComponent/SnackbarComponent';

export default function NotesTab() {
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [rowData, setRowData] = useState(null);

  const notesColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 80,
      flex:0
    },
    {
      field: "entityTypeDescription",
      headerName: "Type",
      minWidth: 150,
      flex:0
    },
    {
      field: "subject",
      headerName: "Subject",
      minWidth: 180,
      flex:1
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 3
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      minWidth: 150,
      flex:1
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      valueGetter: (params) =>
        params.row.modifiedDate
          ? dayjs(params.row.modifiedDate).format("MM-DD-YYYY")
          : "", // add null check
      minWidth: 150,
      flex:1
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 90,
      flex:0,
      renderCell : (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon className="MuiIcons" />
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={()=>onAddOrEditNotes("edit", params?.row)}
              color="inherit"
              key="Edit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon className="MuiIcons" data-testid='delete-icon'/>
                </Tooltip>
              }
              label="Delete"
              onClick={() =>
                handleDeleteClick(params?.row)
              }
              color="inherit"
              key="Delete"
            />,
          ];
      }
    },
  ];

  const [fetchNotesData, { data }] = useLazyQuery(GET_NOTES_DATA, { variables: state });
  const notes = data?.getNotes?.notes || [];

  const [deleteNote] = useMutation(DELETE_NOTE, {
    onCompleted: () => {
      showSnackbar("Note deleted successfully!", "success"); // Show success snackbar
      setIsProcessing(false);
    },
    onError: (error) => {
      showSnackbar(`Error deleting note: ${error.message}`, "error"); // Show error snackbar
      setIsProcessing(false);
    },
    refetchQueries: [GET_NOTES_DATA]
  });

  useEffect(() => {
    if (inquiryTarget) {
      // Destructure data
      const { client, matter } = inquiryTarget;

      if (client) {
        // Set states
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : null,
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (state) {
        fetchNotesData();
    }
  }, [state]);

  const onAddOrEditNotes = (action,rowData)=>{
    setAction(action);
    setRowData(rowData);
    setOpen(true);
  }

  const handleDeleteClick = (rowData) => {
    setSelectedNote(rowData);
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedNote(null);
  }

  const handleConfirmDelete = () => {
    setIsProcessing(true);
    deleteNote({
      variables: { id: selectedNote.id },
    });
    handleDialogClose();
  }

  return (
    <Box className="tab-content">
      <Box className="content-header">
        <Typography variant="h6" paddingLeft={1}>
          Notes
        </Typography>
        <Box display="flex" alignItems="center">
          <StyledLoadingButton
            onClick={() => onAddOrEditNotes('add')}
            id={'Add Notes'}
            variant="outlined"
            style={{
              color: "#0050C3",
              minWidth: "80px",
              maxWidth: "80px",
              fontSize: "12px",
              whiteSpace: "nowrap"
            }}
          >
            {'Add Notes'}
          </StyledLoadingButton>
        </Box>
      </Box>
      <Box>
        <NotesModal
          open={open}
          setOpen={setOpen}
          type={action}
          data={rowData}
          delete={()=>handleDeleteClick(rowData)}
          showSnackbar={showSnackbar}
        />
        <DataTable
          rows={notes}
          columns={notesColumns}
        />
      </Box>
      <SnackbarComponent />
      <ConfirmationDialog
        open={dialogOpen}
        title={'Confirmation'}
        message={`Are you sure you want to delete this note, '${selectedNote?.subject}'?`}
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        onConfirm={handleConfirmDelete}
        onCancel={handleDialogClose}
        isProcessing={isProcessing}
       />
    </Box>
  );
}
