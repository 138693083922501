import { gql } from '@apollo/client';

export const GET_INQUIRY_AR_ADJUSTMENTS = gql`
  query GetInquiryArAdjustmentsQuery(
    $inquiryArAdjustmentRequest: InquiryArAdjustmentRequest
  ) {
    getInquiryArAdjustments(
      inquiryArAdjustmentRequest: $inquiryArAdjustmentRequest
    ) {
      count
      feesTotal
      expensesTotal
      surchargesTotal
      interestTotal
      taxesTotal
      advancesTotal
      ledgersTotal
      appliedTotal
      unappliedTotal
      totalSum

      inquiryArAdjustment {
        id
        activityTypeID
        entryDate
        statusID
        status
        customerID
        clientCode
        jobCode
        clientDescription
        jobDescription
        feeAmount
        expenseAmount
        taxAmount
        interestAmount
        surchargeAmount
        retainerAmount
        amount
        entryDescription
        adjustmentCode
      }
    }
  }
`;
