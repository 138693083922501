import { gql } from '@apollo/client';

const ADD_NOTE = gql`
mutation SaveNote($saveNoteRequest: SaveNoteRequest!) {
    saveNote(saveNoteRequest: $saveNoteRequest) {
        id
        createdById
        createdDate
        modifiedById
        modifiedDate
        entityId
        subject
        description
        category
    }
}
`;

export default ADD_NOTE;