import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import './ConfirmationDialog.scss'
import { GridCloseIcon } from '@mui/x-data-grid';

export default function ConfirmationDialog({open,title,message,cancelLabel,confirmLabel,onConfirm,onCancel,isProcessing }) {

  return (
    <div >
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={'alert-box-parent'}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography className = {"TitleLabel"}>{title}</Typography>
          <Button
            className="PopupBTcloseHeader"
            onClick= {onCancel}
            sx={{ float: "right" }}
          >
           <GridCloseIcon className='close-icon'/>
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography className = {'MessageLabel'}>{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>

        <Button size='small'  className = {'CancelButton'} variant="outlined" onClick={onCancel}><Typography >{cancelLabel}</Typography></Button>
          <Button variant="contained" size='small' onClick={onConfirm} autoFocus className = {'ConfirmButton'}>
            
          {isProcessing ? <CircularProgress sx={{p:'1px', color:'#2DA0EB'}} /> :
            <Typography >{confirmLabel}</Typography>}
          </Button>

         
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
