const jsUtils = {
  parseHash: (hashUrl) => {
    return hashUrl
      ?.replace('#', '')
      .split('&')
      .reduce((prev, item) => {
        return Object.assign(
          {
            [item.split('=')[0]]: decodeURIComponent(
              item.split('=')[1]
            ),
          },
          prev
        );
      }, {});
  },
  currencyFormatter: (amount) => {
    // Convert amount to a number if it's not already
    const numberAmount = Number(amount);

    // Check if the conversion is successful
    if (isNaN(numberAmount)) {
      return '-';
    }

    // Format the amount as currency with the US dollar sign
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
    }).format(numberAmount);

    return formattedAmount.replace('$', '$ ');
  },

  negativeCurrencyFormatter: (amount) => {
    // Convert amount to a number if it's not already
    const numberAmount = Number(amount);

    // Check if the conversion is not successful or the value is 0 or less than 1
    if (isNaN(numberAmount) || numberAmount === 0) {
      return '-';
    }

    // Determine sign based on number
    const isNegative = numberAmount < 0;
    const absoluteAmount = Math.abs(numberAmount);

    // Format the amount as currency with the US dollar sign
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
    }).format(absoluteAmount);

    let currencyWithDollar = formattedAmount.replace('$', '$ ');
    // Return formatted amount with parentheses for negatives
    return isNegative
      ? `(${currencyWithDollar})`
      : currencyWithDollar;
  },

  negativeNumberFormatter: (value) => {
    // Convert value to a number if it's not already
    const numberValue = Number(value);

    // Check if the conversion is successful
    if (isNaN(numberValue) || numberValue === 0) {
      return '-';
    }

    // Determine sign based on numbers
    const isNegative = numberValue < 0;
    const positiveNumber = Math.abs(numberValue).toFixed(2);

    // Return Positive Number with parentheses for negatives
    return isNegative ? `$ (${positiveNumber})` : `$ ${positiveNumber}`;
  },

  convertTo12Hour: (time24) => {
    // Split the time into hours and minutes
    let [hours, minutes] = time24.split(':');
    hours = parseInt(hours);

    // Determine AM or PM suffix
    const suffix = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12 || 12;

    // Return the formatted time
    return `${hours}:${minutes} ${suffix}`;
  }
};
export default jsUtils;
