import { gql } from "@apollo/client";

const GET_BILLS_DATA = gql`
  query getInquiryBills(
    $clientId: Int!
    $jobId: Int
    $startDate: LocalDate!
    $endDate: LocalDate!
  ) {
  getInquiryBills(inquiryBillRequest: { 
    clientId: $clientId, 
    jobId: $jobId, 
    startDate: $startDate, 
    endDate: $endDate
  }) {
    count
    timeFeesTotal
    taskFeesTotal
    simpleFeesTotal
    allFeesTotal
    feesVariance
    feesBilled
    expensesTotal
    expensesVariance
    expensesBilled
    discountTotal
    surchargesTotal
    interestTotal
    taxesTotal
    retainerTotal
    billedTotal
    paidTotal
    adjestedTotal
    dueTotal      
    bills{
        id
        referenceNumber
        activityTypeId
        billDate
        statusId
        status
        cutoffDate
        customerId
        clientCode
        jobCode
        clientDescription
        jobDescription
        amountBilled
        amountPaid
        amountAdjusted
        amountDue
        feeAmountBilled
        feeDiscountBilled
        expenseAmountBilled
        expenseDiscountBilled
        interestBilled
        surchargeBilled
        taxBilled
        retainerBilled
        hours
        feeAmount
        timeFeeAmount
        taskFeeAmount
        simpleFeeAmount
        hoursBilled
        expenseAmount
        amount
        billInstruction  
    }
  }
}

`;

export default GET_BILLS_DATA;
