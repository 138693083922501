import { gql } from '@apollo/client';

const GET_WIP_LEDGER = gql`
  query getWIPLedger($clientId: Int!, $jobId: Int!, $startDate: String!, $endDate: String!) {
    getWIPLedger(wipLedgerRequest: {
      clientId: $clientId,
      jobId: $jobId,
      startDate: $startDate,
      endDate: $endDate
    }) {
      count

      wipLedgers {
        id
        ledgerTypeID
        ledgerDate
        statusID
        status
        employeeID
        employeeCode
        employeeDescription
        customerID
        clientCode
        jobCode
        clientDescription
        jobDescription
        billableTypeID
        billable
        expenseCodeID
        hours
        quantity
        rate
        amount
        balance
        description
      }
    }
  }
`;

export default GET_WIP_LEDGER;
